// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  isCookieRequired: true,
  production: false,
  API_END_POINT: "https://api-qrm.dev.zsservices.com/",
  serverUrl: "https://api-qrm.dev.zsservices.com/",
  authLoginUrl:
    "https://idm.dev.zsservices.com/QRM/IdentityManager/app/Web/login.aspx?ReturnUrl=",
  authLogOutUrl:
    "https://idm.dev.zsservices.com/QRM/IdentityManager/app/Web/logout.aspx?ReturnUrl=https%3A%2F%2Fqrm.staging.zsservices.com"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
