import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-dialog-content',
  templateUrl: './confirm-dialog-content.component.html',
  styleUrls: ['./confirm-dialog-content.component.scss']
})
export class ConfirmDialogContentComponent implements OnInit {
  
  // message to be displayed
  message: string = "Are you sure?"
  // confirm button text
  confirmButtonText = "Yes"
  // cancel button text
  cancelButtonText = "Cancel"
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmDialogContentComponent>) {
      if(data){
    this.message = data.message || this.message;
    if (data.buttonText) {
      this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
      this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
    }
      }
  }

  ngOnInit() {

  }

  // event emitter for confirm dialog
  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
