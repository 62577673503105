import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

class Option {
  message: string;
  type: string;
  time: 5000;
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnChanges {

  @Input() option: Option;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.closeNotification();
    }, this.option.time);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.option.previousValue) {
      if (changes.option.previousValue.message !== changes.option.currentValue.message) {
        this.option.message = changes.option.previousValue.message + " " +  this.option.message;
      }
    }
  }

  closeNotification() {
    this.closed.emit(true);
  }

}
