import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AutofocusDirective } from "./directives/autofocus.directive";
import { NotesComponent } from "./components/notes/notes.component";
import { CommonModule } from "@angular/common";
import { NotificationModule } from "src/app/shared/modules/notification/notification.module";
import { DatepickerDialogComponent } from "./components/datepicker-dialog/datepicker-dialog.component";
import {
  MatDatepickerModule,
  MatNativeDateModule,
  MatDialogModule
} from "@angular/material";
import { ReplaceValuePipe } from "./pipes/replace-value.pipe";
import { DisclaimerComponent } from "./components/disclaimer/disclaimer.component";
import { EmailSidebarComponent } from './components/email-sidebar/email-sidebar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogContentComponent } from './components/confirm-dialog/confirm-dialog-content.component';
import { TilesComponent } from './components/tiles/tiles.component'
import { TooltipDirective } from './tooltip.directive';
import { DatePipe } from "@angular/common";


@NgModule({
  declarations: [
    AutofocusDirective,
    NotesComponent,
    DatepickerDialogComponent,
    ReplaceValuePipe,
    DisclaimerComponent,
    EmailSidebarComponent,
    ConfirmDialogComponent,
    ConfirmDialogContentComponent,
    TilesComponent,
    TooltipDirective
  ],
  providers: [DatePipe],
  imports: [
    CommonModule,
    NotificationModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    ReactiveFormsModule,
    NgSelectModule,
    AngularEditorModule
  ],
  entryComponents: [DatepickerDialogComponent, ConfirmDialogContentComponent],
  exports: [
    AutofocusDirective,
    NotesComponent,
    DatepickerDialogComponent,
    ReplaceValuePipe,
    DisclaimerComponent,
    EmailSidebarComponent,
    ConfirmDialogComponent,
    TilesComponent
  ]
})
export class SharedModule {}
