import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceValue'
})
export class ReplaceValuePipe implements PipeTransform {

  /**
   * Pipe to replace the value with the new value
   * @param value - value to replace
   * @param newValue - new value to show
   * @param toMatch - condition to match for replacing the value
   */
  transform(value: string, newValue?: string, toMatch?: string): any {
    
    if (typeof value === "number") {
      const intValue = new Number(value);
      value = intValue.toString();
    }

    if (value) {
      if (toMatch && value === toMatch) {
        return newValue;
      } else {
        return value;
      }
    } else {
      return "-";
    }

  }

}
