import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private token: string;
  private role: string;
  private userId: string;


  constructor() {
    const tokenID = localStorage.getItem('id');
    const tokenRole = localStorage.getItem('role');
    const tokenUserId = localStorage.getItem('userId');
    if (tokenID) {
      this.token = tokenID;
      this.role = tokenRole;
      this.userId = tokenUserId;
    }
  }

  public setToken(token) {
    this.token = token.id;
    this.role = token.role;
    this.userId = token.userId;
    localStorage.setItem('id', token.id);
    localStorage.setItem('role', token.role);
    localStorage.setItem('userId', token.userId);
  }

  public getToken() {
    return this.token;
  }

  public getrole() {
    return this.role;
  }

  public removeToken() {
    this.token = null;
    this.role = null;
    this.userId = null;
    localStorage.removeItem('id');
    localStorage.removeItem('role');
    localStorage.removeItem('userId');
  }

  public isAuthenticated(): boolean {
    return !!this.token && !!this.role;
  }

  public saveItem(id, data) {
    localStorage.setItem(id, data);
  }

  public getItem(id) {
    return localStorage.getItem(id);
  }

  public removeItem(id) {
    localStorage.removeItem(id);
  }
}
