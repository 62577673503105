import { Injectable } from "@angular/core";
import { LocalStorageService } from "./local-storage.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class AuthGuardService {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  canActivate() {
    return true;
    // try {
    //   if (this.localStorageService.isAuthenticated()) {
    //     return true;
    //   } else {
    //     this.router.navigate(['/login']);
    //     return false;
    //   }
    // } catch (error) {
    //   console.log(error);
    //   return false;
    // }
  }
}
