import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpSentEvent,
  HttpHeaders
} from "@angular/common/http";
import { Observable, throwError, EMPTY } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
  providedIn: "root"
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private _authService: LocalStorageService) {}

  /**
   * Method for customize request.
   * @param request http request
   */
  addCommonHttpOptions(request): HttpRequest<any> {
    try {
      let req = request.clone({});
      const token = this._authService.getToken();
      const role = this._authService.getrole();
      if (!!token && !!role) {
        req = request.clone({
          headers: new HttpHeaders({
            User: token,
            Role: role
          })
        });
      }
      req.withCredentials = environment.isCookieRequired;
      return req;
    } catch (error) {
      console.log(error);
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
  > {
    const customRequest = this.addCommonHttpOptions(req);
    return next.handle(customRequest).pipe(
      map(res => res),
      catchError(err => {
        if (err.status === 401) {
          const url = encodeURIComponent(window.location.origin);
          window.location.href = environment.authLoginUrl + url;
          return EMPTY;
        } else {
          return throwError(err);
        }
      })
    );
  }
}
