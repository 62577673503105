import { Component, OnInit } from '@angular/core';
import { AppSettings } from "../../../configuration/AppSettings";
import { HttpLayerService } from "../../services/http-layer.service";
import { Router } from "@angular/router";
import { SpinnerService } from "../../services/spinner.service";
import {LocalStorageService} from "../../services/local-storage.service";
import { MatDialog } from "@angular/material/dialog";
import { DatepickerDialogComponent } from "src/app/shared/components/datepicker-dialog/datepicker-dialog.component";
import { MatDatepickerInputEvent } from "@angular/material";
import { DatePipe } from "@angular/common";

// Made interface to get data from datepicker dialog using these variables
export interface DialogData {
  startDate: any;
  endDate: any;
  tomorrow: any;
}

@Component({
  selector: 'app-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.sass']
})
export class TilesComponent implements OnInit {

  constructor(private spinnerService: SpinnerService, private httpLayerService: HttpLayerService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private spinner: SpinnerService,
    private datePipe: DatePipe) {
  }

  userInfo;
  tilesData = '';

  public eDate = new Date();
  public startDate;
  public endDate; 
  tomorrow = new Date();
  timeZoneOffset = new Date().getTimezoneOffset();
  startEvents: any[];
  endEvents: any[];
  errorMsg = false;

  ngOnInit() {
    this.resetDates();
  }

  // Resetting dates in datepicker once navigated to new report
 public resetDates() {
  let curentYear = new Date().getFullYear();
  this.startDate = curentYear+"-01-01";
  this.eDate = new Date();
  this.endDate = this.getFormattedDate(this.eDate);
  this.getTilesData();
}

// To get date in formatted manner
getFormattedDate(str: Date) {
  const newDate = this.datePipe.transform(str, "yyyy-MM-dd");
  return newDate;
}

  validateDates(){
    this.errorMsg = false;
    if(this.startDate && this.endDate){
      let a = new Date(this.startDate)
      let b = new Date(this.endDate)
      if(a < b){
        this.getTilesData();
      }else{
        this.errorMsg = true;
        this.spinnerService.tiggerSpinner = false;
        return false;
      }
      
    }
  }

  getTilesData() {
    try {
      this.spinnerService.tiggerSpinner = true;
      const url = AppSettings.SERVICE_IDENTIFEIR.GET_KPI_DATA;
      const payload = {
        user:this.localStorageService.getToken(),
        role_name: this.localStorageService.getrole(),
        startDate: this.startDate,
        endDate: this.endDate
      };
      this.httpLayerService.post(url, payload).subscribe(
        result => {
          if (result.status === "OK") {
            this.tilesData = result.data;
          }
          this.spinnerService.tiggerSpinner = false;
        },
        error => {
          this.spinnerService.tiggerSpinner = false;
          console.log(error);
        }
      );
    } catch (error) {
      this.spinnerService.tiggerSpinner = false;
      console.log(error);
    }
  }

  numFormatter(num) {
    if(num < 1000){
      return '$'+num; // Just add $
    }else if(num >= 1000 && num < 1000000){
      return '$'+(num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million
    }else if(num >= 1000000){
          return '$'+(num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million
      }
}


// Getting start date from user from ui
addStartEvent(type: string, event: MatDatepickerInputEvent<Date>) {
  this.startEvents = [];
  this.startEvents.push(`${event.value}`);
  if (this.startEvents.length == 1) {
    this.startDate = this.getFormattedDate(this.startEvents[0]);
    if (this.startDate && this.endDate) {
      this.validateDates();
    }
  }
}

// Getting end date from user from ui
addEndEvent(type: string, event: MatDatepickerInputEvent<Date>) {
  this.spinner.tiggerSpinner = true;
  this.endEvents = [];
  this.endEvents.push(`${event.value}`);
  if (this.endEvents.length == 1) {
    this.endDate = this.getFormattedDate(this.endEvents[0]);
    if (this.startDate && this.endDate) {
      this.validateDates();
    }
  }
}

}
