import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AppSettings } from "src/app/configuration/AppSettings";
import { LocalStorageService } from "../../services/local-storage.service";
import { HttpLayerService } from "../../services/http-layer.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";

class Option {
  message: string;
  type: string;
  time: number;
}

@Component({
  selector: "app-notes",
  templateUrl: "./notes.component.html",
  styleUrls: ["./notes.component.scss"]
})
export class NotesComponent implements OnInit {
  // Identify if the note is for Cadence or RAQ
  @Input() riskType;
  // Emit when notes slider is closed.
  @Output() closed = new EventEmitter();
  // Display Notification/Error Messages.
  public showNotification = false;
  // The note to be added.
  public note: string;
  // Used to display notification message
  public notificationOption: Option;
  // list of existingNotes to be displayed.
  public existingNotes = [];
  // the opportunity Id
  public opportunityId;
  public role: string;

  constructor(
    private localStorageService: LocalStorageService,
    private httpLayerService: HttpLayerService,
    private spinner: SpinnerService
  ) {}

  ngOnInit() {
    this.role = this.localStorageService.getrole();
    this.opportunityId = sessionStorage.getItem("opportunityId");
    this.loadNotes();
  }

  public notificationClosed() {
    this.showNotification = false;
  }

  public closeNotes() {
    this.closed.emit(false);
  }

  public saveNote(navigate: boolean) {
    if (!this.note) {
      this.showNotification = true;
      this.notificationOption = {
        message: "Please Type a Note to be Saved",
        type: "error",
        time: 5000
      };
      return;
    }

    this.note = this.note.replace(/\'/g, "''");
    this.spinner.tiggerSpinner = true;
    const url = AppSettings.SERVICE_IDENTIFEIR.SAVE_NOTE;
    const payload = {
      userId: this.localStorageService.getItem("userId"),
      opportunityId: this.opportunityId,
      riskType: this.riskType,
      note: this.note
    };

    this.httpLayerService.post_text(url, payload).subscribe(
      result => {
        result = JSON.parse(result);
        if (result.status === "OK") {
          this.note = "";
          this.showNotification = true;
          this.notificationOption = {
            message: result.message,
            type: "success",
            time: 5000
          };
          this.ngOnInit();
        } else {
          this.showNotification = true;
          this.notificationOption = {
            message: result.message,
            type: "error",
            time: 5000
          };
        }
        this.spinner.tiggerSpinner = false;
      },
      error => {
        this.spinner.tiggerSpinner = false;
        console.log(error);
      }
    );
    if (navigate) {
      this.closeNotes();
    }
  }

  public loadNotes() {
    this.spinner.tiggerSpinner = true;
    const url = AppSettings.SERVICE_IDENTIFEIR.GET_NOTE;
    const payload = {
      opportunityId: this.opportunityId,
      riskType: this.riskType
    };

    this.httpLayerService.post(url, payload).subscribe(
      result => {
        this.existingNotes = result.message;
        this.spinner.tiggerSpinner = false;
      },
      error => {
        this.spinner.tiggerSpinner = false;
        console.log(error);
      }
    );
  }
}
