import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmDialogContentComponent } from './confirm-dialog-content.component'

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  // The message to be displayed.
  @Input() message = '';
  // Emit when notes slider is closed.
  @Output() closed = new EventEmitter();

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    this.openDialog();
  }

  openDialog() {
    //TODO: accept button names as input as well
    const dialogRef = this.dialog.open(ConfirmDialogContentComponent,{
      data:{
        message: this.message,
        buttonText: {
          ok: 'Ok',
          cancel: 'Cancel'
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.closed.emit(result);
    });
  }

}
