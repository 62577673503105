import { Injectable } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";

@Injectable({
  providedIn: "root"
})
export class SpinnerService {
  private spinner = new Subject<any>();
  constructor() {}

  set tiggerSpinner(value) {
    this.spinner.next(value);
  }

  get showSpinner() {
    return this.spinner.asObservable();
  }
}
