import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class HttpLayerService {
  constructor(private http: HttpClient) {}

  delete(urlkey: string): Observable<any> {
    return this.http.delete(urlkey, { observe: "response" }).pipe(
      map(resp => this.handleResponse(resp)),
      catchError(this.handleError)
    );
  }

  get(urlkey: string): Observable<any> {
    return this.http.get(urlkey, { observe: "response" }).pipe(
      map(resp => this.handleResponse(resp)),
      catchError(this.handleError)
    );
  }

  get_text(urlkey: string): Observable<any> {
    return this.http
      .get(urlkey, { responseType: "text", observe: "response" })
      .pipe(
        map(resp => this.handleResponse(resp)),
        catchError(this.handleError)
      );
  }

  post(urlkey: string, payload: any): Observable<any> {
    return this.http.post(urlkey, payload, { observe: "response" }).pipe(
      map(resp => this.handleResponse(resp)),
      catchError(this.handleError)
    );
  }

  post_text(urlkey: string, payload: any): Observable<any> {
    return this.http
      .post(urlkey, payload, { responseType: "text", observe: "response" })
      .pipe(
        map(resp => this.handleResponse(resp)),
        catchError(this.handleError)
      );
  }

  put(urlkey: string, payload: any): Observable<any> {
    return this.http.put(urlkey, payload, { observe: "response" }).pipe(
      map(resp => this.handleResponse(resp)),
      catchError(this.handleError)
    );
  }

  /** handle error */
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if (error.status === 401) {
      // Unauthorized Access
    } else if (error.status === 500) {
      // Internal Server Error
    } else {
      return throwError(errorMessage);
    }
  }

  /** */
  handleResponse(resp) {
    if (resp.status === 200 || resp.status === 201 || resp.status === 202) {
      return resp.body;
    } else {
      window.alert(resp.statusText);
    }
  }
}
