import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "../../services/local-storage.service";
import { HttpLayerService } from "../../services/http-layer.service";
import { AppSettings } from "src/app/configuration/AppSettings";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  public userName: string;
  public role: string;
  public headers: Array<any>;
  private selectedMenu: string;
  public profilePicture: string;

  constructor(
    private localStorageService: LocalStorageService,
    private httpLayerService: HttpLayerService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loadHeader();
    this.getUserDetail();
    this.fetchProfilePicture();
  }
  /**
   * to get UserDetail to show in header
   */
  getUserDetail() {
    try {
      this.userName = this.localStorageService.getToken();
      this.role = this.localStorageService.getrole();
    } catch (error) {
      console.log(error);
    }
  }

  formatUserName(name){
    let formatedName = name.substring(0, name.lastIndexOf("@"));
    let FirstLastName = formatedName.split('.');
    let CorrectFormat = FirstLastName[0] + ' ' + FirstLastName[1]
    return CorrectFormat;
  }

  loadHeader() {
    try {
      const url = AppSettings.SERVICE_IDENTIFEIR.HEADER_MENU;
      this.httpLayerService.get(url).subscribe(
        result => {
          if (result.status === "OK") {
            this.headers = result.message[this.role];
            this.setActiveTab();
          }
        },
        error => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }
  fetchProfilePicture() {
    try {
      this.profilePicture = "assets/images/profile.svg";
      // const url = AppSettings.SERVICE_IDENTIFEIR.s;
      // this.httpLayerService.get(url).subscribe(result => {
      //   if (result.status === 'OK' && result.message.imageAvailabe) {
      //     this.profilePicture = result.message.src;
      //   } else {
      //     this.profilePicture = 'assets/images/profile.svg';
      //   }
      // });
    } catch (error) {
      this.profilePicture = "assets/images/profile.svg";
      console.log(error);
    }
  }

  setActiveTab() {
    const url = window.location.href;
    const route = this.headers.filter(item => url.includes(item.route));
    this.selectedMenu = route.length == 1 ? route[0].id : route.length == 2 ? route[1].id: "opportunitiesAndrojects";
    // this.selectedMenu = this.headers.filter(item => url.includes(item.route))[0].id;
  }
  dispalyhide = "hidelogout";
  naviagetePage(menu, isDisabled) {
    if (isDisabled) {
      return false;
    }
    this.selectedMenu = menu.id;
    this.router.navigate([`/${menu.route}`]);
  }
  allowLogoutUser() {
    this.dispalyhide = "displaylogout";
  }
  closelogout() {
    this.dispalyhide = "hidelogout";
  }

  logout() {
    window.location.href = AppSettings.SERVICE_IDENTIFEIR.LOGOUT;
    this.localStorageService.removeToken();
    localStorage.clear();
    sessionStorage.clear();
  }
}
