import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthGuardService } from "./shared/services/auth-guard.service";
import { SpinnerService } from "./shared/services/spinner.service";
import { NgxSpinnerService } from "ngx-spinner";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from "@angular/router";
import { HttpLayerService } from "./shared/services/http-layer.service";
import { AppSettings } from "./configuration/AppSettings";
import { LocalStorageService } from "./shared/services/local-storage.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  private spinnerSubscriber: any;
  public showHead: boolean = false;
  public showNotification = false;
  public notificationOption: object;

  constructor(
    authGuardService: AuthGuardService,
    private spinnerService: SpinnerService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private httpLayerService: HttpLayerService,
    private localStorageService: LocalStorageService
  ) {
    authGuardService.canActivate();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        let currentUrl = event.url;
        this.showHead = !["/login", "/"].includes(currentUrl);
      }
    });

    /**
     * Route change detection for loader.
     */
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.spinnerService.tiggerSpinner = true;
      }

      // if (event instanceof NavigationEnd) {
      //   this.spinnerService.tiggerSpinner = false;
      // }

      if (event instanceof NavigationError) {
        this.spinnerService.tiggerSpinner = false;
      }
    });
  }
  ngOnInit() {
    if (this.localStorageService.isAuthenticated()) {
      if (window.location.href.endsWith("/#")) {
        this.router.navigate(["/opportunity"]);
      }
      // this.router.navigate(["/opportunity"]);
    } else {
      this.router.navigate(["/login"]);
    }

    this.spinnerSubscriber = this.spinnerService.showSpinner.subscribe(
      (result) => {
        result ? this.spinner.show() : this.spinner.hide();
      }
    );
  }
  ngOnDestroy() {
    this.spinnerSubscriber.unsubscribe();
  }
}
