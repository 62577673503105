import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDatepickerInputEvent,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDatepicker
} from "@angular/material";
import { DialogData } from "src/app/report/views/view-reports/view-reports.component";
import { DatePipe } from "@angular/common";

import * as _moment from "moment";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from "moment";

const moment = _rollupMoment || _moment;

import { FormControl } from "@angular/forms";

@Component({
  selector: "app-datepicker-dialog",
  templateUrl: "./datepicker-dialog.component.html",
  styleUrls: ["./datepicker-dialog.component.scss"],
  providers: [DatePipe]
})
export class DatepickerDialogComponent implements OnInit {
  ngOnInit() {}

  startEvents: any[];
  endEvents: any[];
  startDate;
  endDate;
  name;
  disableDownload = true;
  dateMsg = false;
  date = new FormControl(moment());
  public isChecked = false;

  constructor(
    public dialogRef: MatDialogRef<DatepickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private datePipe: DatePipe
  ) {
    this.name = this.data['name'];
    sessionStorage.setItem("QRMPortalHistory", "false");
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  //Get date in formatted manner
  getFormattedDate(str) {
    const newDate = this.datePipe.transform(str, "yyyy-MM-dd");
    return newDate;
  }

  addStartEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startEvents = [];
    this.startEvents.push(`${event.value}`);
    if (this.startEvents.length == 1) {
      this.startDate = this.getFormattedDate(this.startEvents[0]);
      this.data.startDate = this.startDate;
      if ((Date.parse(this.endDate) <= Date.parse(this.startDate))) {
        this.dateMsg = true;
      }
      else{
        if (this.startDate && this.endDate) {
          this.disableDownload = false;
          this.dateMsg = false;
        }
      }
      
    }
  }

  addEndEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endEvents = [];
    this.endEvents.push(`${event.value}`);
    if (this.endEvents.length == 1) {
      this.endDate = this.getFormattedDate(this.endEvents[0]);
      this.data.endDate = this.endDate;
      if ((Date.parse(this.endDate) <= Date.parse(this.startDate))) {
        this.dateMsg = true;
      }
      else{
        if (this.startDate && this.endDate) {
          this.disableDownload = false;
          this.dateMsg = false;
        }
      }
    }
  }


  loadHistoricatData(event) {
    if (event == "A") {
      this.isChecked = true;
      sessionStorage.setItem("QRMPortalHistory", "true");
      this.disableDownload = false;
      this.startDate = null;
      this.endDate = null;
    } else {
      this.isChecked = false;
      sessionStorage.setItem("QRMPortalHistory", "false");
      this.disableDownload = true;
    }
  }

  /* startYearHandler(normalizedYear: Moment) {
    this.data.startDate = normalizedYear;
  }

  startMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    this.data.startDate = normalizedMonth;
    let date1 = this.getFormattedDate(normalizedMonth);
    let date2 = date1.split("-");
    date2.pop();
    this.startDate = date2.join("-");
    this.data.startDate = this.startDate;
    datepicker.close();
    if (this.startDate && this.endDate) {
      this.disableDownload = false;
    }
  }

  endYearHandler(normalizedYear: Moment) {
    this.data.endDate = normalizedYear;
  }

  endMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.data.endDate = normalizedMonth;
    let date1 = this.getFormattedDate(normalizedMonth);
    let date2 = date1.split("-");
    date2.pop();
    this.endDate = date2.join("-");
    this.data.endDate = this.endDate;
    datepicker.close();
    if (this.startDate && this.endDate) {
      this.disableDownload = false;
    }
  } */
}
