import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuardService } from "./shared/services/auth-guard.service";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: "login",
    loadChildren: "./login/login.module#LoginModule"
  },
  {
    path: "dashboard",
    loadChildren: "./dashboard/dashboard.module#DashboardModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "opportunity",
    loadChildren: "./opportunity/opportunity.module#OpportunityModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "communication",
    loadChildren: "./communication/communication.module#CommunicationModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "report",
    loadChildren: "./report/report.module#ReportModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "manage",
    loadChildren: "./manage/manage.module#ManageModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "opportunity/project-infomation/:id",
    loadChildren:
      "./project-information/project-information.module#ProjectInformationModule",
    canActivate: [AuthGuardService]
  },
  {
    path: "opportunity/project-infomation",
    redirectTo: "/opportunity",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
