import { environment } from "../../environments/environment";

export class AppSettings {
  // Base URL for Service call
  public static get API_END_POINT(): string {
    return environment.API_END_POINT;
  }

  public static get authLogOutUrl(): string {
    return environment.authLogOutUrl;
  }

  // Base URL for static jsons
  public static get STATIC_JSON_END_POINT(): string {
    return "assets/json/";
  }

  public static GET_USER: any = {
    LOGIN_USERS: "http://rest.zs.local/api/Employee/"
  };
  public static SERVICE_IDENTIFEIR: any = {
    HEADER_MENU: AppSettings.STATIC_JSON_END_POINT + "menu.json",
    OPPORTUNITIES_MENU:
      AppSettings.STATIC_JSON_END_POINT + "opportunities-menu.json",
    PROJECT_INFORMATION_MENU:
      AppSettings.API_END_POINT + "fetch_project_information_menu",
    INTIAL_QRM_MENU:
      AppSettings.STATIC_JSON_END_POINT + "initial-qrm-menu.json",
    MANAGE_USER_MENU: AppSettings.STATIC_JSON_END_POINT + "manage-user.json",
    OPPORTUTNITY_DETAILS_DROPDOWN:
      AppSettings.API_END_POINT + "fetchOpportunityDropDown",
    ADD_NEW_OPPORTUNITY: AppSettings.API_END_POINT + "add_new_opportunity",
    UPDATE_OPPORTUNITY: AppSettings.API_END_POINT + "update_opportunity",
    GET_TORECIPIENT_CCRECIPIENTDTL:
      AppSettings.API_END_POINT + "get_non_compliance_data",
    SEND_RESPONSETIME_MAIL:
      AppSettings.API_END_POINT + "send_non_compliance_mail",
    REVERT_RESPONSETIME_MAIL:
      AppSettings.API_END_POINT + "clear_non_compliance_mail",

    MY_OPPORTUNITY: AppSettings.API_END_POINT + "myOpportunity",
    ALL_OPPORTUNITY: AppSettings.API_END_POINT + "allOpportunity",
    MY_PROJECT: AppSettings.API_END_POINT + "myProject",
    ALL_PROJECT: AppSettings.API_END_POINT + "allProject",
    save_cadence_date: AppSettings.API_END_POINT + "save_cadence_date",

    OPPORTUNITIES_INFO_STATIC:
      AppSettings.STATIC_JSON_END_POINT + "opportunity-info.json",
    OPPORTUNITIES_INFO: AppSettings.API_END_POINT + "fetch_opportunity_info",
    OPPORTUNITIES_DETAILS:
      AppSettings.API_END_POINT + "fetch_opportunity_details",
    SEND_RAQ: AppSettings.API_END_POINT + "send_raq",
    RAQ_STATUS: AppSettings.API_END_POINT + "fetch_raq_status",

    BEGIN_CADENCE: AppSettings.API_END_POINT + "begin_cadence",
    CALL_DETAILS_TABLE_DATA: AppSettings.API_END_POINT + "fetch_call_details",
    UPDATE_CALL_DETAILS_RISK:
      AppSettings.API_END_POINT + "update_call_details_risk_status",
    DELETE_CALL_DETAILS_RISK:
      AppSettings.API_END_POINT + "delete_call_details_risk",
    FETCH_SUBCATEGORY_AND_TOTAL_SCORE_CALL_DETAILS:
      AppSettings.API_END_POINT +
      "fetch_subcategory_and_total_score_call_details",
    LOGIN: AppSettings.API_END_POINT + "login",
    UserDetails: AppSettings.API_END_POINT + "userInfo",
    ISUSERAUTHRISED: AppSettings.API_END_POINT + "is_user_authorized",
    // Risk
    CATEGORY: AppSettings.API_END_POINT + "fetch_category",
    SUBCATEGORY: AppSettings.API_END_POINT + "fetch_subcategory",
    RISKSTATUS: AppSettings.API_END_POINT + "fetchall_status",
    RISK_SCORES: AppSettings.API_END_POINT + "risk_score",
    INITIATE_QRM_PROPAGATION:
      AppSettings.API_END_POINT + "initiate_qrm_propagation",
    FETCH_RISK_TAGS: AppSettings.API_END_POINT + "fetch_risk_tags",
    FETCH_RISK_SUMMARIES: AppSettings.API_END_POINT + "fetch_risk_summaries",
    FETCH_RISK_MITIGATION: AppSettings.API_END_POINT + "fetch_risk_mitigation",
    SAVE_RISK: AppSettings.API_END_POINT + "save_risk",
    FETCH_RISK_GRID: AppSettings.API_END_POINT + "fetch_risk_table",
    DELETE_RISK: AppSettings.API_END_POINT + "delete_risk",
    FETCH_SUBCATEGORY_AND_TOTAL_SCORE:
      AppSettings.API_END_POINT + "fetch_subcategory_and_total_score",
    FETCH_CALL_DETAILS_RISK_TAGS:
      AppSettings.API_END_POINT + "fetch_call_details_risk_tags",
    FETCH_CALL_DETAILS_RISK_SUMMARIES:
      AppSettings.API_END_POINT + "fetch_call_details_risk_summaries",
    FETCH_CALL_DETAILS_RISK_MITIGATION:
      AppSettings.API_END_POINT + "fetch_call_details_risk_mitigation",
    SAVE_CALL_DETAILS_RISK:
      AppSettings.API_END_POINT + "save_call_details_risk",
    CALL_SUMMARY: AppSettings.API_END_POINT + "risk_summary",
    FETCH_ALL_MONTHS_SCORES:
      AppSettings.API_END_POINT + "fetch_all_months_risk_scores",
    UPLOAD_RISK: AppSettings.API_END_POINT + "upload_risk",

    RISK_TABLE_DATA_STATIC:
      AppSettings.STATIC_JSON_END_POINT + "risk-table.json",

    // RAQ - wizard
    QUESTIONNAIRE_WIZARD_STATIC:
      AppSettings.STATIC_JSON_END_POINT + "questionnaire-wizard.json",
    PROJECT_OVERVIEW_QUESTIONS:
      AppSettings.API_END_POINT + "fetchProjectOverview",
    CLIENT: AppSettings.API_END_POINT + "fetchClient",
    DELIVERY: AppSettings.API_END_POINT + "fetchDelivery",
    TECHNOLOGY: AppSettings.API_END_POINT + "fetchTechnology",
    DOMAIN: AppSettings.API_END_POINT + "fetchDomain",
    ZSCOMMITMENT: AppSettings.API_END_POINT + "fetchZSCommitment",
    SEND_REMINDER: AppSettings.API_END_POINT + "send_reminder",

    RISK_SCORES_STATIC: AppSettings.STATIC_JSON_END_POINT + "risk_scores.json",

    OPPORTUNITIES_GRID_DATA:
      AppSettings.STATIC_JSON_END_POINT + "all-opportunities.json",
    CALL_SUMMARY_JSON:
      AppSettings.STATIC_JSON_END_POINT + "call_summary_risk_score.json",
    // FETCH_ALL_MONTHS_SCORES: AppSettings.STATIC_JSON_END_POINT + "fetch_all_months_scores.json"

    //Logout
    LOGOUT: AppSettings.authLogOutUrl,

    //Notes
    SAVE_NOTE: AppSettings.API_END_POINT + "save_note",
    GET_NOTE: AppSettings.API_END_POINT + "get_note",

    SEND_RISKSCORE_MAIL: AppSettings.API_END_POINT + "send_risk_mail",
    PARTICIPANT_TABLE_DATA: AppSettings.API_END_POINT + "loadLOV",
    SAVE_PARTICIPANT: AppSettings.API_END_POINT + "manageLOV",


    //Dashboard
    GET_KPI_DATA: AppSettings.API_END_POINT + "fetchUserKpiData",
    GET_NEW_OPPORTUNITIES: AppSettings.API_END_POINT + "gridOpportunity",



    GET_KPI_DATA_STATIC: AppSettings.STATIC_JSON_END_POINT + "tilesData.json",
    GET_NEW_OPPORTUNITIES_STATIC: AppSettings.STATIC_JSON_END_POINT + "newOpp.json"
  };
}
