import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppSettings } from "src/app/configuration/AppSettings";
import { HttpLayerService } from "../../services/http-layer.service";
import { SpinnerService } from "src/app/shared/services/spinner.service";

class Option {
  message: string;
  type: string;
  time: number;
}
@Component({
  selector: "app-email-sidebar",
  templateUrl: "./email-sidebar.component.html",
  styleUrls: ["./email-sidebar.component.scss"]
})
export class EmailSidebarComponent implements OnInit {
  // Mail body of the mail to be sent
  @Input() mailbody = "";
  // Initial List of people to include in TO
  @Input() tolist = [];
  // Initial list of people to include in CC
  @Input() cclist = [];
  // holds the mail subject
  @Input() subject = "";
  // Emit when notes slider is closed.
  @Output() closed = new EventEmitter();
  // Form Group for Mail
  public sendMailFormGroup: FormGroup;
  // List of people to include as TO
  public toitems = [];
  // List of people to include as CC
  public ccitems = [];
  // Configuration for Email template
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "auto",
    minHeight: "calc(50vh)",
    maxHeight: "calc(50vh)",
    width: "auto",
    minWidth: "0",
    translate: "no",
    enableToolbar: true,
    showToolbar: true,
    defaultFontSize: "2"
  };
  //TODO: get avlblToAndCcitems also as an Input
  // contains list of people used in people picker.
  public avlblToAndCcitems = [];
  // Used to display notification message
  public notificationOption: Option;
  // Display Notification/Error Messages.
  public showNotification = false;

  constructor(
    private sendMailFormBuilder: FormBuilder,
    private httpLayerService: HttpLayerService,
    private spinner: SpinnerService
  ) {}

  /**
   * to get the form controls
   */
  get mailFormcontrols() {
    return this.sendMailFormGroup.controls;
  }

  ngOnInit() {
    this.toitems = this.tolist;
    this.ccitems = this.cclist;
    this.loadMailData();
    this.loadToAndCCRecipientList();
  }

  /**
   * Close the mail sidebar
   */
  public closeEmailSidebar() {
    this.closed.emit(false);
  }

  /**
   * Load Mail data into form group.
   */
  public loadMailData() {
    this.sendMailFormGroup = this.sendMailFormBuilder.group({
      textareaval: [null, Validators.required],
      toitems_: [this.toitems, Validators.required],
      ccitems_: [this.ccitems]
    });
    this.mailFormcontrols.textareaval.setValue(this.mailbody);
  }

  /**
   * load to recipient and cc recipient list
   */
  public loadToAndCCRecipientList() {
    try {
      const url = AppSettings.SERVICE_IDENTIFEIR.SEND_RISKSCORE_MAIL;
      const payload = {
        opportunityId: sessionStorage.getItem("opportunityId")
      };
      this.httpLayerService.post(url, payload).subscribe(
        result => {
          if (result.status === "OK") {
            this.avlblToAndCcitems = result.message.nonComplianceReceivers.map(
              i => ({ itemName: i.itemName, value: i.email })
            );
          }
        },
        error => {
          console.log(error);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * send Mail
   */
  public sendMail() {
    this.spinner.tiggerSpinner = true;
    if (this.mailFormcontrols.toitems_.value.length < 1) {
      this.spinner.tiggerSpinner = false;
      this.showNotification = true;
      this.notificationOption = {
        message: "Please add atleast one recipient to TO",
        type: "error",
        time: 5000
      };
    } else {
      const url = AppSettings.SERVICE_IDENTIFEIR.SEND_RISKSCORE_MAIL;
      const payload = {
        toMails: this.mailFormcontrols.toitems_.value,
        ccMails: this.mailFormcontrols.ccitems_.value,
        mailBody: this.mailFormcontrols.textareaval.value,
        subject: this.subject,
        opportunityId: sessionStorage.getItem("opportunityId")
      };

      this.httpLayerService.post(url, payload).subscribe(
        result => {
          if (result.status === "OK") {
            console.log("mail sent", result.message);
            this.showNotification = true;
            this.notificationOption = {
              message: result.message,
              type: "success",
              time: 5000
            };
            this.spinner.tiggerSpinner = false;
            setTimeout(() => {
              this.closeEmailSidebar();
            }, 2000);
          } else if (result.status === "ERROR") {
            console.log("something went wrong");
            this.showNotification = true;
            this.notificationOption = {
              message: result.message,
              type: "error",
              time: 1000
            };
            this.spinner.tiggerSpinner = false;
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  public notificationClosed() {
    this.showNotification = false;
  }
}
